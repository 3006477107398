<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>COIN OUT</sub-title>

                <div class="main_panel">
                    <div class="re_info">
                        <span>
                            <strong><i class="fa fa-star" aria-hidden="true"></i></strong>
                              {{i18nConst.COIN['COIN_OUT_MSG_1'][$store.state.country]}}
                        </span>
                    </div>
                    <!--                    <div class="re_cont">-->
                    <!--                        <div class="c1">-->
                    <!--                            예금명-->
                    <!--                        </div>-->
                    <!--                        <div class="c2" style="">-->
                    <!--                            <span>{{$store.state.userInfo.beneficiary}}</span>-->
                    <!--                        </div>-->
                    <!--                    </div>-->

                    <div class="re_cont">
                        <div class="c1">
                            {{i18nConst.USER['USER_COIN'][$store.state.country]}}
                        </div>
                        <div class="c2" style="flex-direction:row;justify-content: flex-start">
                            <span style="color: #0c0e0e">ETH: {{$store.state.ethBalance}}</span>
                            <!--                            <span style="margin-left: 10px;color: #0b3987">STA: {{$store.state.staBalance}}</span>-->
                            <span style="padding: 0 5px;cursor: pointer" @click="refreshCoin">
                                <i class="fa fa-refresh"></i>
                            </span>
                        </div>
                    </div>
                    <div class="re_cont">
                        <div class="c1">
                            {{i18nConst.COIN['COIN_RATE'][$store.state.country]}}
                        </div>
                        <div class="c2" style="flex-direction:row;justify-content: flex-start">
                            <span style="color: #0c0e0e">ETH: {{$store.state.ethPrice}} $</span>
                            <!--                            <span style="margin-left: 10px;color: #0b3987">STA: {{$store.state.staPrice}} $</span>-->
                        </div>
                    </div>
                    <div class="re_cont">
                        <div class="c1">
                            {{i18nConst.COIN['CONVERTIBLE_COINS'][$store.state.country]}}
                        </div>
                        <div class="c2" style="flex-direction:row;justify-content: flex-start">
                            <span style="color: #0c0e0e">ETH: {{$store.state.userInfo.cash / $store.state.ethPrice}} $</span>
                            <!--                            <span style="margin-left: 10px;color: #0b3987">STA: {{$store.state.userInfo.cash / $store.state.staPrice}} $</span>-->
                        </div>
                    </div>
                    <div class="re_cont">
                        <div class="c1">
                            {{i18nConst.COMMON['AMOUNT'][$store.state.country]}}
                        </div>
                        <div class="c2" style="">
                            <!--                            <div style="display: flex;justify-content: flex-start;align-items: center;padding: 10px 0">-->
                            <!--                                <div>-->
                            <!--                                    <input id="eth" name="cointype" type="radio" v-model="applyCash.cointype" :value="sportsConst.ETH">-->
                            <!--                                    <label for="eth"> ETH</label>-->
                            <!--                                </div>-->
                            <!--                                <div style="margin-left: 10px">-->
                            <!--                                    <input id="sta" name="cointype" type="radio" v-model="applyCash.cointype" :value="sportsConst.STA">-->
                            <!--                                    <label for="sta"> STA</label>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <div style="display: flex;justify-content: flex-start;align-items: center;padding: 10px 0">
                                <input type="text" v-model="applyCash.amount" class="re_input" placeholder=""
                                       style="">
                                <button type="button" class="btn-re" @click="changeMoney(0)"
                                        style="width: 130px;margin-left: 6px">
                                    {{i18nConst.BET['RESET'][$store.state.country]}}
                                </button>
                            </div>
                            <div style="display: flex; justify-content: flex-start;width: 100%;padding: 10px 0">
                                <button type="button" class="btn-re" @click="changeMoney(10)">
                                    10$
                                </button>
                                <button type="button" class="btn-re" @click="changeMoney(50)">
                                    50$
                                </button>
                                <button type="button" class="btn-re" @click="changeMoney(100)">
                                    100$
                                </button>
                                <button type="button" class="btn-re" @click="changeMoney(200)">
                                    200$
                                </button>
                                <button type="button" class="btn-re" @click="changeMoney(500)">
                                    500$
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="rec_buttons" style="">
                        <button class="b1" @click="exchange">
                            {{i18nConst.COIN['CONVERTIBLE_COINS_REQUEST'][$store.state.country]}}
                        </button>
                    </div>
                </div>

                <div class="main_panel">
                    <div class="re_info">
                        <span>
                            <strong><i class="fa fa-star" aria-hidden="true"></i></strong>
                              {{i18nConst.COIN['COIN_OUT_MSG_2'][$store.state.country]}}
                        </span>
                    </div>

                    <div class="re_cont">
                        <div class="c1">
                            {{i18nConst.USER['USER_COIN'][$store.state.country]}}
                        </div>
                        <div class="c2" style="flex-direction:row;justify-content: flex-start">
                            <span style="color: #0c0e0e">ETH: {{$store.state.ethBalance}}</span>
                            <!--                            <span style="margin-left: 10px;color: #0b3987">STA: {{$store.state.staBalance}}</span>-->
                            <span style="padding: 0 5px;cursor: pointer" @click="refreshCoin">
                                <i class="fa fa-refresh"></i>
                            </span>
                        </div>
                    </div>
                    <div class="re_cont">
                        <div class="c1">
                            {{i18nConst.COIN['COIN_RATE'][$store.state.country]}}
                        </div>
                        <div class="c2" style="flex-direction:row;justify-content: flex-start">
                            <span style="color: #0c0e0e">ETH: {{$store.state.ethPrice}} $</span>
                            <!--                            <span style="margin-left: 10px;color: #0b3987">STA: {{$store.state.staPrice}} $</span>-->
                        </div>
                    </div>
                    <div class="re_cont">
                        <div class="c1">
                            {{i18nConst.COIN['COIN_OUT_WALLET_ADDRESS'][$store.state.country]}}
                        </div>
                        <div class="c2" style="flex-direction:row;justify-content: flex-start">
                            <div>
                                <input id="userWalletAddress" name="userWalletAddress" type="text"
                                       v-model="applyCash2.userWalletAddress" class="wallet">
                            </div>
                        </div>
                    </div>
                    <div class="re_cont">
                        <div class="c1">
                            {{i18nConst.COMMON['AMOUNT2'][$store.state.country]}}
                        </div>
                        <div class="c2" style="">
                            <!--                            <div style="display: flex;justify-content: flex-start;align-items: center;padding: 10px 0">-->
                            <!--                                <div>-->
                            <!--                                    <input id="eth" name="cointype" type="radio" v-model="applyCash.cointype" :value="sportsConst.ETH">-->
                            <!--                                    <label for="eth"> ETH</label>-->
                            <!--                                </div>-->
                            <!--                                <div style="margin-left: 10px">-->
                            <!--                                    <input id="sta" name="cointype" type="radio" v-model="applyCash.cointype" :value="sportsConst.STA">-->
                            <!--                                    <label for="sta"> STA</label>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <div style="display: flex;justify-content: flex-start;align-items: center;padding: 10px 0">
                                <input type="text" v-model="applyCash2.coinAmount" class="re_input" placeholder=""
                                       style="">
                                <button type="button" class="btn-re" @click="setCoinAmount(0)"
                                        style="width: 130px;margin-left: 6px">
                                    {{i18nConst.BET['RESET'][$store.state.country]}}
                                </button>
                            </div>
                            <div>
                                {{applyCash2.coinAmount * $store.state.ethPrice}} $
                            </div>
                            <div style="display: flex; justify-content: flex-start;width: 100%;padding: 10px 0">
                                <button type="button" class="btn-re" @click="setCoinAmount(10)">
                                    10%
                                </button>
                                <button type="button" class="btn-re" @click="setCoinAmount(25)">
                                    25%
                                </button>
                                <button type="button" class="btn-re" @click="setCoinAmount(50)">
                                    50%
                                </button>
                                <button type="button" class="btn-re" @click="setCoinAmount(100)">
                                    100%
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="rec_buttons" style="">
                        <button class="b1" @click="sendCoin">
                            {{i18nConst.COIN['CONVERTIBLE_COINS_REQUEST2'][$store.state.country]}}
                        </button>
                    </div>
                </div>


                <div class="re_history">
                    <table cellpadding="0" cellspacing="0" border="0">
                        <colgroup>
                            <col width="27%">
                            <col width="27%">
                            <col width="27%">
                            <col width="15%">
                        </colgroup>
                        <thead>
                        <tr>
                            <th>{{i18nConst.COMMON['DATE'][$store.state.country]}}</th>
                            <th>{{i18nConst.COMMON['AMOUNT'][$store.state.country]}}</th>
                            <th @click="refreshData()" style="cursor: pointer">
                                {{i18nConst.COMMON['STATUS'][$store.state.country]}} <i class="fa fa-refresh"
                                                                                        aria-hidden="true"></i>
                            </th>
                            <th>-</th>
                        </tr>
                        <tr v-for="(item,indx) in exchangeList" :key="indx">
                            <td>{{item.createTime|datef('MM/DD HH:mm')}}</td>
                            <td>{{item.amount|comma}}</td>
                            <td>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_WAITING">{{i18nConst.COMMON['UNIDENTIFIED'][$store.state.country]}}</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_CHECKING">{{i18nConst.COMMON['CHECKING'][$store.state.country]}}</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_COMPLITE"><i class="fa fa-check"
                                                                                                  aria-hidden="true"></i></span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_CANCEL"
                                      style="color: red">{{i18nConst.COMMON['FAILURE'][$store.state.country]}}</span>
                            </td>
                            <td>
                  <span
                          style="display: inline-block;width: 60px; height: 20px; line-height: 20px;text-align: center;cursor: pointer"
                          v-if="item.status !== sportsConst.APPLY_STATUS_WAITING && item.status
                        !== sportsConst.APPLY_STATUS_CHECKING" @click="deleteById(item.id)">
                     <i class="fa fa-close"></i> {{i18nConst.COMMON['DELETE'][$store.state.country]}}
                  </span>

                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>


</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import {deleteApply, exchangeCash, getApplyCashList, rechargeCash, sendcoin} from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {getAccountBalance, subjectMemberCasinoCash} from "../../network/casinoRequest";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import i18nConst from "../../common/i18nConst";

    export default {
        name: "Exchange",
        mixins: [postionMixin],
        components: {
            SportsBetCartComp,
            RightButtonsComp,
            UserInfoComp,
            SportsLeftBarComp,
            RightBarBannerComp, FootComp, TopbarComp, LeisureLeftComp, LeftBarComp, RightBarComp, SubTitle
        },
        data() {
            return {
                sportsConst,
                applyCash: {
                    amount: 0,
                    extractPasswd: '',
                    cointype: sportsConst.ETH,

                },
                applyCash2: {
                    coinAmount: 0,
                    extractPasswd: '',
                    cointype: sportsConst.ETH,

                },
                search: {applyType: sportsConst.APPLY_TYPE_EXCHANGE},
                exchangeList: [],
                casinocash: 0,
                position: "출금",
                i18nConst: i18nConst,
            }
        },
        methods: {
            refreshCoin() {
                this.$store.dispatch('actionCoinPriceAndBalance')
                this.$store.dispatch('actionUserInfo')
            },
            changeMoney(cash) {
                if (cash === 0) {
                    this.applyCash.amount = 0;
                } else {
                    this.applyCash.amount += cash;
                }
            },
            setCoinAmount(persent) {
                let coin = this.$store.state.ethBalance;
                coin = coin * (persent / 100);
                this.applyCash2.coinAmount = coin;

            },
            exchange() {

                this.$swal({
                    title: this.applyCash.amount + '$ 전환신청 하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' ' + i18nConst.COMMON['YES'][this.$store.state.country] + ' ',
                    cancelButtonText: i18nConst.COMMON['NO'][this.$store.state.country]
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        this.$store.commit(RECEIVE_SHOW_LOADING)
                        exchangeCash(this.applyCash).then(res => {
                            this.$store.commit(RECEIVE_HIDE_LOADING)
                            if (res.data.success) {
                                this.$swal({
                                    title: i18nConst.COMMON['REQUST_COMPLETED'][this.$store.state.country],
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.$store.dispatch('actionUserInfo')
                                this.initExchangeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            sendCoin() {
                this.$swal({
                    title: this.applyCash2.coinAmount + ' 전송 하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' ' + i18nConst.COMMON['YES'][this.$store.state.country] + ' ',
                    cancelButtonText: i18nConst.COMMON['NO'][this.$store.state.country]
                }).then((res) => {
                    if (res.value) {
                        this.$store.commit(RECEIVE_SHOW_LOADING)
                        sendcoin(this.applyCash2).then(res => {
                            this.$store.commit(RECEIVE_HIDE_LOADING)
                            if (res.data.success) {
                                this.$swal({
                                    title: i18nConst.COMMON['REQUST_COMPLETED'][this.$store.state.country],
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.$store.dispatch('actionUserInfo')
                                this.initExchangeList()
                                this.refreshCoin()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },

            initExchangeList() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getApplyCashList(this.search).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.exchangeList = res.data.data
                    }
                })
            },
            refreshData() {
                this.initExchangeList()
                this.$bus.$emit('applyRequestSuccess')
            },
            deleteById(id) {
                this.$swal({
                    title: i18nConst.COMMON['CAN_DELETE_MSG_1'][this.$store.state.country],
                    type: 'question',
                    confirmButtonText: ' ' + i18nConst.COMMON['YES'][this.$store.state.country] + ' ',
                    cancelButtonText: i18nConst.COMMON['NO'][this.$store.state.country]
                }).then((res) => {
                    if (res.value) {
                        deleteApply(id).then(res => {
                            if (res.data.success) {
                                this.initExchangeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            getCasinoBalance() {
                getAccountBalance().then(res => {
                    if (res.data.data) {
                        this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                    } else {
                        this.$set(this.$store.state.userInfo, 'casinocash', 0)
                    }
                })
            }
        },
        created() {
            this.getCasinoBalance()
            this.initExchangeList()
            this.$store.dispatch('actionCoinPriceAndBalance')
        },
        mounted() {
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/reex.css");
</style>